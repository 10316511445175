<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>新增赞助</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="approval-detail-info">
            <div class="approval-detail-container">
                <div class="name-container">
                    <span>{{ detail.true_name | sub_name }}</span>
                    <div class="state-container">
                        <div class="content">{{ detail.true_name }}</div>
                        <div class="state">{{ detail.detail.state | state }}</div>
                    </div>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <project-info :cid="detail.project.cid" :info="detail.project_attach"></project-info>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">可用{{$getTitleByType('会议', detail.project.cid)}}基金：</span>
                    <span class="approval-detail-content">{{ detail.detail.meet_run }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">类型：</span>
                    <span class="approval-detail-content">{{ detail.detail.type_str }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">申请原因：</span>
                    <span class="approval-detail-content">{{ detail.detail.amount_desc }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">与主任确认截图：</span>
                    <span class="approval-detail-content"> <el-image :src="detail.detail.confirm_img" style="width: 150px; height: 150px" :preview-src-list="[detail.detail.confirm_img]"></el-image></span>
                </div>
                <div class="approval-detail-title" v-if="detail.detail.state === 2">
                    <span class="approval-detail-tip red">驳回原因：</span>
                    <span class="approval-detail-content red">{{ detail.detail.state_msg }}</span>
                </div>
            </div>
            <div class="approval-list-container">
                <div class="approval-list-tip">审批流程</div>
                <div class="approval-list">
                    <div class="name-container active">
                        <span>{{ detail.true_name | sub_name }}
                          <i class="el-icon-success"></i>
                        </span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">{{ detail.true_name }}</div>
                                <div class="info-time">{{ detail.detail.create_time }}</div>
                            </div>
                            <div class="info-desc">发起新增赞助申请</div>
                        </div>
                    </div>

                    <div class="name-container">
                        <span>财务<i :class=" detail.detail.state === 0
                        ? 'el-icon-remove' : detail.detail.state === 1
                        ? 'el-icon-success' : 'el-icon-error'"></i></span>
                        <div class="info-container">
                            <div class="info-top">
                                <div class="info-content">财务对接人</div>
                                <div v-if="detail.detail.sp_time" class="info-time">
                                    {{ detail.detail.sp_time }}
                                </div>
                            </div>
                            <div class="info-desc">
                                {{ detail.detail.state | state }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="approval-btn">

                <el-button class="approval-btn-left"
                           v-if="member_type == 0 && detail.detail.state == 0"
                           @click="showDialog(2)">拒绝
                </el-button>
                <el-button class="approval-btn-right"
                           v-if="member_type == 0 && detail.detail.state == 0"
                           @click="showDialog(1)">通过
                </el-button>
            </div>
        </div>
        <el-dialog
            :before-close="closeDialog"
            :visible.sync="editVisible"
            center
            width="30%"
        >
      <span slot="title" class="dialog-title">{{
              state === 1 ? "审批同意确认" : "审批拒绝原因"
          }}</span>
            <div class="dialog-container flex-center">
                <el-input
                    v-model="remark"
                    :placeholder="
            state === 1 ? '请输入审批同意意见' : '请输入审批拒绝原因'
          "
                    :rows="5"
                    resize="none"
                    type="textarea"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button :disabled="requestState" :loading="requestState" type="primary" @click="toApproval()">确定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
import {getPathIndex, sub_name, webUrl, ZHDate} from "../../tools";
import ProjectInfo from "../components/ProjectInfo";

export default {
    components: {
        ProjectInfo
    },
    name: "inseSponsorDetail",
    data() {
        return {
            pid: "",
            member_type: -1,
            isApprovaler: false,
            editVisible: false,
            requestState: false,
            detail: '',
            remark: "",
            cur_id: "",

        };
    },
    created() {
        this.$emit("setIndex", [0, getPathIndex(this.$route.query.type)]);
        this.init();
    },
    methods: {
        watchState() {
            this.printDialogVisible = false
        },
        cainaDialog(id) {
            this.editVisible = true;
            this.cur_id = id
        },
        showDialog(type) {
            this.editVisible = true;
            this.state = type;
            this.remark = this.state === 1 ? "同意" : "";
        },
        closeDialog() {
            this.remark = ''
            this.editVisible = false;
            this.state = -1;
        },
        toApproval() {
            if (!this.remark) {
                this.$message.error(
                    this.state === 1 ? "请输入审批同意意见" : "请输入审批拒绝原因"
                );
                return;
            }
            this.requestState = true
            this.$api.apiContent
                .inseSponsorAudit({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    state: this.state,
                    state_msg: this.remark,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            state: this.state,
                            state_msg: this.remark,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.closeDialog();
                    this.$message.success(res.msg);
                    this.init();
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        },
        init() {
            this.detail = {}
            this.pid = this.$route.query.id;
            this.member_type = this.$route.query.type;
            this.$api.apiContent
                .inseSponsorDetail({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.pid,
                    type: this.$route.query.cctype ? this.$route.query.cctype : "",
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.pid,
                            type: this.$route.query.cctype ? this.$route.query.cctype : "",
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    res.data.time =
                        this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                    this.detail = res.data
                })
                .catch(() => {
                    this.detail = {};
                });
        },
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        backPage() {
            this.$tools.goBack();
        },
    },
    filters: {
        filePath(url) {
            return webUrl(url)
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "审核中";
                case 2:
                    return "已拒绝";
                default:
                    return "已通过";
            }
        },
        type(type) {
            switch (Number(type)) {
                case 1://1 新增 ， 2 编辑 ，3 删除
                    return "新增";
                case 2:
                    return "编辑";
                default:
                    return "删除";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
    computed: {},
    watch: {
        $route: "init",
    },
};
</script>

<style lang="scss" scoped>
.page-box {
    .dialog-confirm-container {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 20px;
        color: #333333;

        .dialog-tip {
            width: 120px;
            flex-shrink: 0;
        }

        .el-input,
        .el-date-editor--datetime,
        .el-date-editor,
        .el-radio-group,
        .el-textarea,
        .el-textarea__inner {
            flex-grow: 1;
            margin: 0 10px;
            font-size: 16px;
        }
    }

    .dialog-title {
        color: #000;
        font-size: 24px;
    }

    .dialog-footer {
        .el-button {
            width: 235px;
        }
    }

    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }
        }
    }

    .approval-detail-info {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        border-radius: 6px;
        min-height: 600px;
        padding-bottom: 100px;

        .approval-detail-file {
            flex: 7;
            flex-shrink: 1;
            display: flex;
            flex-direction: column;

            a {
                font-size: 18px;
                cursor: pointer;
                margin-bottom: 8px;
                display: block;
                color: #3b77e7;

                &:hover {
                    font-size: 18px;
                }
            }
        }

        .printing {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        .approval-btn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 50%;
            position: absolute;
            bottom: 60px;
            padding-left: 120px;

            .approval-btn-left {
                width: 180px;
                background: transparent;
                border: 1px solid #3b77e7;
                color: #3b77e7;
            }

            .approval-btn-right {
                width: 180px;
                margin-left: 20px;
                background: #3b77e7;
                color: #ffffff;
            }
        }

        .approval-list-container {
            display: flex;
            flex-direction: row;
            padding: 40px 80px 0;

            &.list-bottom {
                padding: 0 80px;
            }

            .approval-list {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                margin-left: 12px;

                .name-container {
                    position: relative;
                    flex-grow: 1;
                    display: flex;
                    padding-bottom: 50px;
                    flex-direction: row;

                    &.active {
                        &:before {
                            position: absolute;
                            background: #d3d4d6;
                            width: 1px;
                            top: 0px;
                            bottom: 0px;
                            left: 28px;
                            content: "";
                        }
                    }

                    .info-container {
                        flex-grow: 1;
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .info-top {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .info-content {
                                font-size: 20px;
                                color: #333333;

                                i {
                                    font-size: 14px;
                                }
                            }

                            .info-time {
                                font-size: 16px;
                                color: #999999;
                            }
                        }

                        .info-desc {
                            margin-top: 2px;
                            font-size: 16px;
                            color: #999999;
                        }

                        .copy-members {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            margin-top: 20px;

                            .copy-members-name {
                                margin-right: 10px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        border: 1px solid #3b77e7;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 16px;
                        color: #ffffff;
                        background-color: #3b77e7;
                        position: relative;

                        i {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            font-size: 18px;
                            width: 18px;
                            height: 18px;
                            overflow: hidden;

                            &.el-icon-remove {
                                color: #ee9900;
                            }

                            &.el-icon-success {
                                color: #13ce66;
                            }

                            &.el-icon-error {
                                color: #ff0000;
                            }
                        }
                    }
                }
            }

            .approval-list-tip {
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
                margin-top: 12px;

                &.list-bottom {
                    color: #ffffff;
                }
            }
        }

        .approval-detail-container {
            padding: 40px;
            border-bottom: 1px solid #f0f2f5;

            .approval-list-tip {
                margin-left: 40px;
                font-size: 18px;
                color: #333333;
                flex-shrink: 1;
            }

            .approval-detail-title {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                font-size: 18px;
                color: #999999;
                width: 100%;

                .approval-detail-tip {
                    flex: 1;
                    flex-shrink: 0;
                    text-align: right;

                    &.red {
                        color: red;
                    }
                }

                .approval-detail-content {
                    flex: 7;
                    flex-shrink: 1;
                    color: #333333;

                    &.red {
                        color: red;
                    }
                }
            }

            .name-container {
                display: flex;
                flex-direction: row;
                padding-left: 40px;

                .state-container {
                    margin-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .content {
                        font-size: 22px;
                        color: #333333;

                        .red {
                            color: red;
                        }
                    }

                    .state {
                        font-size: 16px;
                        margin-top: 6px;
                        color: #13ce66;

                        .red {
                            color: red;
                        }
                    }
                }

                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 65px;
                    height: 65px;
                    border-radius: 65px;
                    text-align: center;
                    cursor: pointer;
                    /*line-height: 24px;*/
                    font-size: 20px;
                    color: #fff;
                    background-color: #3b77e7;
                }
            }
        }
    }

    .sign-list {
        width: 100%;
        padding: 34px 28px;

        .table-item {
            text-align: center;
            font-size: 16px;
            color: #666666;

            .image-sign {
                width: 150px;
            }
        }
    }

    .file_link {
        color: #3b77e7;
        font-size: 16px;
        text-decoration: underline;
        margin-top: 10px;
    }
}
</style>
